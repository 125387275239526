<template>
  <div>
    <product-batch
      @updated="
        (_) => {
          productPath = _;
          getProduct();
        }
      "
      :dialog="productPath"
    />
    <brochure
      @updated="
        (_) => {
          addBrochure = _;
          getBrochures();
        }
      "
      :dialog="addBrochure"
    />
    <add-study
      @updated="
        (_) => {
          addStudy = _;
        }
      "
      :product="product"
      :dialog="addStudy"
    />
    <add-offer
      @updated="
        (_) => {
          addOffer = _;
        }
      "
      @added="
        (_) => {
          addOffer = _;
          getOffers();
        }
      "
      :product="product"
      :dialog="addOffer"
    />
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            {{ product.tradeName }}

            <v-spacer></v-spacer>
            <back-button></back-button>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> {{ $t("batchs") }} </v-tab>
              <v-tab> {{ $t("brochure") }} </v-tab>
              <v-tab> {{ $t("studies") }} </v-tab>
              <v-tab> {{ $t("offers") }} </v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-title>
                    {{ $t("batchs") }}
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="openProductBatch(product)"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>
                      {{ $t("add") }} {{ $t("batchs") }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      disable-pagination
                      :headers="headers"
                      :items="product.productBatches"
                      hide-default-footer
                      :loading="loading"
                    >
                      <template v-slot:item.expire="{ item }">
                        {{ $service.formatDate(new Date(item.expire), false) }}
                      </template>
                      <template v-slot:item.numberOfUnitsPerBox="{ item }">
                        {{ item.numberOfUnitsPerBox }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title>
                    {{ $t("brochures") }}
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="addBrochure = true"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>
                      {{ $t("add") }} {{ $t("brochure") }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <h3 class="text-center" v-if="brochures.length < 1">
                      {{ $t("no-data") }}
                    </h3>
                    <div v-else>
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="brochure in brochures"
                          :key="brochure.id"
                        >
                          <v-card
                            outlined
                            class="d-flex flex-column"
                            color="grey"
                          >
                            <v-card-title>
                              {{ brochure.name }}
                              <v-spacer></v-spacer>
                              <v-btn
                                color="info"
                                text
                                @click="$htmlToPaper(brochure.id)"
                              >
                                <v-icon> mdi-printer </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text :id="brochure.id">
                              <img
                                :src="brochure.path"
                                style="
                                  width: 100%;
                                  height: 100%;
                                  object-fit: cover;
                                  overflow: hidden;
                                "
                                class="img-fluid"
                              />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title>
                    {{ $t("studies") }}
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="addStudy = true"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>
                      {{ $t("add") }} {{ $t("study") }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <h3 class="text-center" v-if="brochures.length < 1">
                      {{ $t("no-data") }}
                    </h3>
                    <div v-else>
                      <studies :product="$route.params.id" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title>
                    {{ $t("offers") }}
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="addOffer = true"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>
                      {{ $t("add") }} {{ $t("offer") }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      disable-pagination
                      :headers="offerHeaders"
                      :items="offers"
                      :loading="loading"
                      hide-default-footer
                      :no-data-text="$t('no-data')"
                    >
                      <template v-slot:item.created="{ item }">
                        <span dir="ltr">
                          {{ $service.formatDate(new Date(item.created)) }}
                        </span>
                      </template>
                      <template v-slot:item.expired="{ item }">
                        <v-chip :color="expiredOffer(item) ? 'error' : ''"
                          ><span dir="ltr">
                            {{ $service.formatDate(new Date(item.expired)) }}
                          </span></v-chip
                        >
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined>
          <v-card-title> {{ $t("image") }} </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-img :src="product.pictureUrl" contain></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="white"></v-divider>
  </div>
</template>

<script>
import ProductBatch from "./ProductBatch";
import Brochure from "./Brochure";
import Studies from "../studies/Studies.vue";
import AddStudy from "../studies/Add.vue";

import AddOffer from "./AddOffer.vue";
import BackButton from "../../../../components/BackButton.vue";
export default {
  components: {
    ProductBatch,
    Brochure,
    Studies,
    AddStudy,
    AddOffer,
    BackButton,
  },
  data() {
    return {
      tab: [],
      products: [],
      product: {},
      addBrochure: false,
      studies: [],
      addStudy: false,
      productPath: false,
      loading: true,
      addOffer: false,
      offers: [],
      offerHeaders: [
        {
          text: this.$t("detials"),
          value: "detials",
        },
        {
          text: this.$t("expired"),
          value: "expired",
        },
        {
          text: this.$t("created"),
          value: "created",
        },
      ],
      brochures: [],
      headers: [
        {
          text: this.$t('batch-no-0'),
          value: "batchNo",
        },
        {
          text: this.$t('number-of-boxes'),
          value: "numberOfBoxes",
        },
        {
          text: this.$t('number-of-units'),
          value: "numberOfUnitsPerBox",
        },
        {
          text: this.$t('available-in-warehouse'),
          value: "totalNumberOfUnit",
        },
        {
          text: this.$t('buy-price'),
          value: "buyPrice",
        },
        {
          text: this.$t('expired'),
          value: "expire",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    expiredOffer(offer) {
      return new Date(offer.expired) < new Date();
    },
    openProductBatch(id) {
      this.$store.commit("products/updateProduct", id);
      this.productPath = true;
    },
    getProduct() {
      this.loading = true;
      this.$http.get(`/products/${this.id}`).then((response) => {
        this.product = response.data.result;
        for (let i = 0; i < this.product.productBatches.length; i++) {
          Object.keys(this.product.productBatches[i]).forEach((key) => {
            if (typeof this.product.productBatches[i][key] == "number") {
              this.product.productBatches[i][key] =
                "" +
                this.$service.formatCurrency(
                  this.product.productBatches[i][key]
                );
            }
          });
        }
        this.product.id = this.id;
        this.loading = false;
      });
    },
    getOffers() {
      this.loading = true;
      this.$http
        .get("/ProductOffers/", {
          params: {
            ProductId: this.id,
          },
        })
        .then((res) => {
          this.offers = res.data.data;
          this.loading = false;
        });
    },
    getBrochures() {
      this.loading = true;
      this.$http
        .get(`/Brochures`, {
          params: {
            ProductId: this.$route.params.id,
            PageSize: 100,
          },
        })
        .then((response) => {
          this.brochures = response.data.data;
          this.loading = false;
        });
    },
    getStudies() {
      this.loading = true;
      this.$http
        .get(`/Studies`, {
          params: {
            ProductId: this.$route.params.id,
            PageSize: 100,
          },
        })
        .then((response) => {
          this.studies = response.data.data;
          this.loading = false;
        });
    },
  },
  created() {
    this.getProduct();
    this.getBrochures();
    this.getOffers();
  },
};
</script>
