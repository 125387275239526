<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>
        {{ $t("add") }} {{ $t("offer") }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addOffer" :loading="loading">
          <v-row>
            <v-col cols="12" md="12">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                class="white"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    class="mt-2"
                    v-model="inputs.expired"
                    :label="$t('expire-date')"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="inputs.expired">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        inputs.expired = '';
                      }
                    "
                  >
                    {{ $t('reset') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                hide-detials="auto"
                outlined
                class="mt-2"
                v-model="inputs.detials"
                :label="$t('detials')"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="d-flex align-center justify-end mt-3">
            <v-btn
              outlined
              @click="
                () => {
                  dialog = false;
                }
              "
              color="primary"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn @click="submit()" class="mx-2" color="primary">
              <v-icon left>mdi-alert</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "product"],
  data() {
    return {
      loading: false,
      inputs: {
        productId: "",
        detials: "",
        expired: "",
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$http.post("/ProductOffers/post", this.inputs).then(() => {
        this.loading = false;
        this.dialog = false;
        this.$emit("added", this.dialog);
      });
    },
  },
  watch: {
    dialog() {
      if (this.dialog != true) {
        this.$refs.addOffer.reset();
      } else {
        this.inputs.productId = this.$route.params.id;
      }

      this.$emit("updated", this.dialog);
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
