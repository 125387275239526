var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("add"))+" "+_vm._s(_vm.$t("offer"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('f-icon',{attrs:{"icon":"times-circle"}})],1)],1),_c('v-card-text',[_c('v-form',{ref:"addOffer",attrs:{"loading":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-menu',{ref:"menu",staticClass:"white",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"hide-detials":"auto","outlined":"","label":_vm.$t('expire-date'),"prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.inputs.expired),callback:function ($$v) {_vm.$set(_vm.inputs, "expired", $$v)},expression:"inputs.expired"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{ref:"picker",model:{value:(_vm.inputs.expired),callback:function ($$v) {_vm.$set(_vm.inputs, "expired", $$v)},expression:"inputs.expired"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                      _vm.inputs.expired = '';
                    }}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"mt-2",attrs:{"hide-detials":"auto","outlined":"","label":_vm.$t('detials')},model:{value:(_vm.inputs.detials),callback:function ($$v) {_vm.$set(_vm.inputs, "detials", $$v)},expression:"inputs.detials"}})],1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex align-center justify-end mt-3"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function () {
                _vm.dialog = false;
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }